import React, { useState } from "react";
import { TaskAlt } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import QuoteApprovalRequest from "../../QuoteApprovalRequest/QuoteApprovalRequest";
import BasicModal from "../../../common/BasicModal/BasicModal";

export default function ApprovalRequestButton({ id, onReload }) {
    const [open, setOpen] = useState(false);

    const onOpenCloseModal = () => setOpen(presState => !presState);


    return (
        <React.Fragment>
            <Tooltip title="Solicitudes de aprobación">
                <IconButton
                    aria-label="approval request quote"
                    color='inherit'
                    size='small'
                    onClick={onOpenCloseModal}
                >
                    <TaskAlt />
                </IconButton>
            </Tooltip>

            <BasicModal
                open={open}
                onClose={onOpenCloseModal}
                title="Aprobación de cotizaciones"
                maxWidth="md"
                fullWidth>
                <QuoteApprovalRequest
                    quoteId={id}
                    onReloadDatatable={onReload}
                    onClose={onOpenCloseModal}
                />
            </BasicModal>
        </React.Fragment>
    );
}
