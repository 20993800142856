import React, { useMemo, useState } from "react";
import { Delete, Edit, RemoveRedEye, RequestQuote } from "@mui/icons-material";
import { IconButton, TableCell, TableRow, Button, useMediaQuery, useTheme, ButtonGroup, styled, Tooltip } from "@mui/material";
import CurrencyFormat from "react-currency-format";
import QuoteFormCost from "../../Quotes/QouteFormCost/QuoteFormCost";
import { deleteRegister } from "../../../apiCore/dataTable";
import { useSnackbar } from "notistack";
import BasicModal from "../../common/BasicModal/BasicModal";
import SelectTypeMixes from "../SelectTypeMixes/SelectTypeMixes";
import ConfirmModal from "../../Controls/ConfirmModal";

const KILOGRAM_PER_TONS = 1000;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    ":hover": {
        backgroundColor: theme.palette.action.hover,
    },
}));

export default function MixesTableItem({ mix, elements, onReload, availableEdit, profit }) {

    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [openFormCost, setOpenFormCost] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [negativeProfitability, setNegativeProfitability] = useState(false);

    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

    const onOpenCloseFormCost = () => setOpenFormCost(prevState => !prevState);
    const onOpenCloseConfirmDelete = () => setOpenConfirmDelete(prevState => !prevState);

    const total = useMemo(() => {
        const {
            quantityTons = 0,
            price = 0,
            totalTons = 0,
            maquilaCost = 0,
            packaging,
            profitability = 0,
            discount = 0,
            flete = 0,
        } = mix || {};

        if (quantityTons) {
            const rawMaterialCost = (price / totalTons) * KILOGRAM_PER_TONS || 0;
            const maquila = (Number(rawMaterialCost) + Number(maquilaCost)) || 0;
            const { value: packagingCapacity = 0, unitPrice = 0 } = packaging || {};
            const packagingQuantity = Number(KILOGRAM_PER_TONS / Number(packagingCapacity)) || 0;
            const subtotal = Number(maquila + packagingQuantity * unitPrice) || 0;
            const withProfitability = Number(subtotal / (1 - (profitability / 100))) || 0;
            const withDiscount = Number(withProfitability * (1 - (discount / 100))) || 0;
            const result = Number(withDiscount) + Number(flete);

            return result * quantityTons;
        } else {
            return 0;
        }
    }, [mix]);

    // Calcular la cantidad de paquetes, según la capacidad del empaque y la cantidad de toneladas.
    const packagingQuantity = useMemo(() => {
        if (mix?.packaging?.value) {
            const result = (Number(KILOGRAM_PER_TONS / mix?.packaging?.value) * Number(mix?.quantityTons)) || 0;
            if (result === 0 || Number.isInteger(result)) return result;
            return parseFloat(result).toFixed(0);
        }
        return "";
    }, [mix]);

    const onDeleteMix = async () => {
        try {
            if (availableEdit) {
                // Eliminar mezcla
                const response = await deleteRegister("mixes", mix?.id);
                const { error, message } = response?.data || {};

                if (error) {
                    enqueueSnackbar(error, { variant: "error" });
                }
                if (message) {
                    enqueueSnackbar(message, { variant: "success" });
                    if (onReload instanceof Function) onReload();
                }
            } else {
                enqueueSnackbar("La cotización no se puede editar", { variant: "error" });
            }

        } catch (err) {
            const { error } = err?.data;
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al intentar eliminar el registro", { variant: "error" });
            }
        }
    }

    return (
        <React.Fragment>
            <StyledTableRow>
                <TableCell>
                    <ButtonGroup>
                        {availableEdit && (
                            <Tooltip title="Eliminar">
                                <IconButton
                                    disabled={!availableEdit}
                                    onClick={onOpenCloseConfirmDelete}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        )}
                        <IconButton
                            onClick={() => setOpen(true)}>
                            {availableEdit ? (
                                <Tooltip title="Editar">
                                    <Edit />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Visualizar">
                                    <RemoveRedEye />
                                </Tooltip>
                            )}
                        </IconButton>
                        <IconButton onClick={() => setOpenFormCost(true)}>
                            <Tooltip title="Asignar costos">
                                <RequestQuote />
                            </Tooltip>
                        </IconButton>
                    </ButtonGroup>
                </TableCell>
                <TableCell className="cellNameMix elementContentLeft">
                    {mix.name}
                </TableCell>
                <TableCell>
                    {mix.type?.name}
                </TableCell>

                {elements.map(element => {
                    const matchElement = mix?.elements?.find(mixElement => mixElement?.elementId === element?.id) || {};
                    return <TableCell className="elementContent" key={element.id}>{matchElement?.value ?? 0}</TableCell>;
                })}
                <TableCell className="cellSeveralMix elementContentRight">
                    {mix.totalTons}
                </TableCell>
                <TableCell className="cellSeveralMixRight elementContentRight">
                    <CurrencyFormat
                        renderText={(value) => <b>{value}</b>}
                        displayType="text"
                        value={mix.price}
                        thousandSeparator
                        prefix="$ "
                        decimalScale={0}
                    />
                </TableCell>
                <TableCell className="cellSeveralMix elementContentRight">
                    {mix?.quantityTons}
                </TableCell>
                <TableCell className="cellSeveralMix elementContentRight">
                    {mix?.packaging?.name}
                </TableCell>
                <TableCell className="cellSeveralMix elementContentRight">
                    {packagingQuantity}
                </TableCell>
                <TableCell className="cellSeveralMix elementContentRight">
                    {mix?.profitability === 0 ? `${mix?.profitability}%` : (mix?.profitability ? `${mix?.profitability}%` : null)}
                </TableCell>                
                <TableCell className="cellSeveralMixRight elementContentRight">
                    <CurrencyFormat
                        renderText={(value) => <b>{value}</b>}
                        displayType="text"
                        thousandSeparator
                        value={Math.ceil(total.toFixed(0)/100)*100}
                        prefix="$ "
                    />
                </TableCell>
            </StyledTableRow>

            {/* Actualizar mezcla */}
            <BasicModal
                open={open}
                onClose={() => setOpen(false)}
                title="Seleccionar tipo de mezcla"
                sxDialogContent={{
                    "&.MuiDialogContent-root": {
                        paddingTop: "5px",
                    },
                }}
                maxWidth="md">
                <SelectTypeMixes
                    onOpenCloseModal={() => setOpen(false)}
                    mix={mix}
                    onReload={onReload}
                    availableEdit={availableEdit}
                />
            </BasicModal>

            {/* Asignar costos */}

            <BasicModal
                style={{height:'calc(100vh - 85px)'}}
                open={openFormCost}
                onClose={onOpenCloseFormCost}
                fullScreen={fullScreen}
                title="Configuración mezcla"
                actions={(
                    <ButtonGroup
                        className="quoteFormCostButtonGroup">
                        <Button
                            variant="contained"
                            onClick={onOpenCloseFormCost}
                            fullWidth>
                            Mezclas
                        </Button>

                        {!negativeProfitability &&
                            <Button
                                form="quote-cost-form"
                                variant="contained"
                                type="submit"
                                fullWidth
                                disabled={!availableEdit}>
                                Guardar
                            </Button>
                        }
                        {negativeProfitability}
                        {negativeProfitability &&
                            <Button
                                form="quote-cost-form"
                                variant="contained"
                                type="submit"
                                fullWidth
                                className="littleButtonText"
                                color="warning"
                                disabled={!availableEdit}>
                                Guardar, con rentabilidad igual a 0 o menor que 0.
                            </Button>
                        }
                    </ButtonGroup>
                )}>
                <QuoteFormCost
                    profit={profit}
                    mix={mix}
                    onReload={onReload}
                    closeModal={onOpenCloseFormCost}
                    availableEdit={availableEdit}
                    setNegativeProfitability={setNegativeProfitability}                    
                />
            </BasicModal>

            {/* Eliminar mezcla */}
            <ConfirmModal
                open={openConfirmDelete}
                setOpen={setOpenConfirmDelete}
                titleMessage="Eliminar mezcla"
                confirmMessage={`¿Confirmar que desea eliminar la mezcla '${mix?.name ?? ""}'?`}
                confirmHandler={onDeleteMix}
                confirmButtonText="Eliminar mezcla"
            />
        </React.Fragment>
    )
}
