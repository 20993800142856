import React, { useEffect, useMemo, useState } from "react";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, tableCellClasses } from "@mui/material";
import CurrencyFormat from "react-currency-format";
import MixesTableList from "./MixesTableList";
import { useSnackbar } from "notistack";
import { getData, loadFullData } from "../../../apiCore/entity";
import { hideLoader, showLoader } from "../../Controls/Loader";
import { ENV } from "../../../utils/constants";
import { getElementsByMix, getFertilizersByMix } from "../../../apiCore/mixes";

const columns = [
    {
        id: 1,
        label: "Acciones",
        align: "",
        colSpan: 1
    },
    {
        id: 2,
        label: "Nombre",
        align: "",
        colSpan: 1
    },
    {
        id: 3,
        label: "Tipo",
        align: "",
        colSpan: 1
    },
    {
        id: 4,
        label: "Elementos",
        align: "",
        colSpan: 1
    },
    {
        id: 5,
        label: "Total (Kg)",
        align: "",
        colSpan: 1
    },
    {
        id: 6,
        label: "Costos",
        align: "",
        colSpan: 1
    },
    {
        id: 7,
        label: "Cantidad (Tn)",
        align: "",
        colSpan: 1
    },
    {
        id: 8,
        label: "Empaque",
        align: "",
        colSpan: 1
    },
    {
        id: 9,
        label: "Empaques",
        align: "",
        colSpan: 1
    },
    {
        id: 10,
        label: "Rentabilidad",
        align: "",
        colSpan: 1
    },
    {
        id: 12,
        label: "Total",
        align: "",
        colSpan: 1
    },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#006530",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    minHeight: "40px",
    height: "40px",
}));

export default function MixesTable({ quote, reload, onReload, setQuote, availableEdit, additionalMix = null }) {
    const { enqueueSnackbar } = useSnackbar();

    const [elements, setElements] = useState([]);
    const [mixes, setMixes] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [profit, setProfit] = useState(null);

    const totalTons = useMemo(() => {
        return mixes?.reduce((acum, mix) => acum + mix?.totalTons, 0);
    });

    const totalPrice = useMemo(() => {
        return mixes?.reduce((acum, mix) => acum + mix?.price, 0);
    });

    const getElements = async () => {
        try {
            const response = await loadFullData({model: ENV.CLIENT_ROUTES.ELEMENTS.MODEL});
            let { error, data } = response?.data || {};
            if (error) throw { error: response };
            if (data) setElements(data);

            console.log("CALL PROFIT")
            const profitability = await loadFullData({model: "profitability", conditions:{id:"(SELECT MAX(id) FROM profitability)"}});
            let dataProfit = profitability?.data || {};
            if (dataProfit.error) throw { error: profitability };

            if(dataProfit.data.length == 1){
                //Rentabilidad vigente
                console.log(dataProfit.data[0]);
                setProfit(dataProfit.data[0]);
            }else{
                throw { error: "Comuniquse con el administrador, no existe parametro de rentabilidad configurado para el proceso de cotización." };
            }
        } catch (err) {
            const { error } = err?.response?.data || {};
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al cargar la lista de elementos", { variant: "error" });
            }
        }
    }

    const getMixes = async () => {
        try {
            const response = await getData({model: ENV.CLIENT_ROUTES.MIXES.MODEL, idSearch: quote?.id, column: "quoteId"});
            const { error, data } = response?.data || {};
            if (error) throw { error: response };

            console.log("GET MIXES")
            console.log(data);
            console.log(additionalMix);

            if (data.length && data.length > 0) {
                const consultedMixes = [];

                for (const mix of data) {
                    let copyMix = { ...mix };
                    // Consultar los elementos y fertilizantes asociados a cada mezcla.
                    const { error: elementError, data: elements } = await getElementsByMix(mix?.id) || {};
                    const { error: fertilizerError, data: fertilizers } = await getFertilizersByMix(mix?.id) || {};

                    if (elementError || fertilizerError) {
                        setIsError(true);
                        return;
                    };

                    copyMix = { ...copyMix, elements, fertilizers };

                    // Consultar el tipo de mezcla.
                    const typeOfMix = await getData({
                        model: ENV.CLIENT_ROUTES.TYPEOFMIX.MODEL,
                        idSearch: mix?.typeOfMixId,
                        column: "id",
                    });
                    const { error: errorTypeOfMix, data: type } = typeOfMix?.data || {};
                    if (errorTypeOfMix) {
                        setIsError(true);
                        return;
                    }

                    if (type) {
                        copyMix = { ...copyMix, type: type[0] };
                    }

                    if (mix?.packagingTypeId) {
                        const resPackaging = await getData({
                            model: ENV.CLIENT_ROUTES.PACKAGINGTYPE.MODEL,
                            idSearch: mix?.packagingTypeId,
                            column: "id",
                        });

                        const { error: errorPackgaging, data: packaging } = resPackaging?.data || {};
                        if (errorPackgaging) {
                            setIsError(true);
                            return;
                        }
                        if (packaging) {
                            copyMix = { ...copyMix, packaging: packaging[0] }
                        }
                    }

                    consultedMixes.push(copyMix)
                }

                setQuote(prevState => ({ ...prevState, mixes: consultedMixes }));
                setMixes(consultedMixes);

            } else {                
                //No se encontraron mezclas
                if(additionalMix){
                    //Se tiene una mezcla por adicionar

                    //additionalMix.mixId
                    //Se verifica que la mezcla no exista previamente en el array de mezclas
                    let tempMixes = mixes;
                    let existMix = false;
                    for(let i=0;i<tempMixes.length;i++){
                        if(tempMixes[i].mixId == additionalMix.mixId){
                            //Mezcla ya registrada, posibles cambios en la estructura de la mezcla
                            tempMixes[i] = {...tempMixes[i], ...additionalMix};
                            existMix = true;
                        }
                    }
                    
                    if(!existMix){
                        tempMixes.push(additionalMix)
                    }

                    setMixes(tempMixes);
                    setQuote(prevState => ({ ...prevState, mixes: mixes }));
                }else{
                    setMixes([]);
                    setQuote(prevState => ({ ...prevState, mixes: [] }));
                }
            }            

        } catch (err) {
            const { error } = err?.response?.data || {};
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar("Error al cargar la mezclas", { variant: "error" });
            }

            setIsError(true);
        }
    }

    useEffect(() => {
        (async () => {
            showLoader();            
            await getElements();
            await getMixes();
            hideLoader();
            setIsLoading(false);
        })();
    }, [reload]);

    if (isError) return (
        <Typography
            className="error"
            variant="h5">
            Error al cargar los datos
        </Typography>
    );

    return (
        <>
            <Box
                className="displayFlex flexDirectionColumn height100 stylaTablePage"
                overflow="auto"
                marginTop={1}>
                <TableContainer
                    className="mixesTable"
                    component={Paper}
                    elevation={5}
                    variant="elevation">
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map(column => {
                                        if (column.label === "Elementos"){
                                            return (
                                                <StyledTableCell
                                                    className="textAlignCenter"
                                                    key={column.id}
                                                    colSpan={elements.length}>
                                                    {column.label}
                                                </StyledTableCell>
                                            )
                                        }else{
                                            return (
                                                <StyledTableCell
                                                    className="cellSeveralCenterMix"
                                                    key={column.id}
                                                    colSpan={column.colSpan}
                                                    rowSpan={2}>
                                                    {column.label}
                                                </StyledTableCell>
                                            )
                                        }
                                    })
                                }
                            </TableRow>
                            <TableRow>
                                {elements.map(element => <StyledTableCell className="elementHeader" key={element.id}>{element.name}</StyledTableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading && (
                                <TableRow>
                                    <StyledTableCell
                                        className="textAlignCenter"
                                        colSpan={elements?.length + 11}>
                                        <Typography variant="body2">
                                            Cargando...
                                        </Typography>
                                    </StyledTableCell>
                                </TableRow>
                            )}

                            {(mixes.length === 0) && !isLoading && (
                                <TableRow>
                                    <StyledTableCell className="textAlignCenter" colSpan={elements?.length + 11}>
                                        <Typography variant="body2">
                                            No hay mezclas registradas
                                        </Typography>
                                    </StyledTableCell>
                                </TableRow>
                            )}
                                
                            <MixesTableList
                                profit={profit}
                                mixes={mixes}
                                elements={elements}
                                onReload={onReload}
                                availableEdit={availableEdit}
                            />
                            {/*}
                            {mixes.length > 0 && (
                                <>
                                    <StyledTableRow>
                                        <StyledTableCell
                                            rowSpan={2}
                                            colSpan={elements?.length ? (elements.length + 9) : 3}
                                        />
                                        <StyledTableCell className="cellSeveralCenterMix">
                                            <strong>Total (Kg)</strong>
                                        </StyledTableCell>
                                        <StyledTableCell className="toRight cellSeveralMixRight">
                                            <CurrencyFormat
                                                renderText={(value) => <b>{value}</b>}
                                                displayType="text"
                                                value={totalTons}
                                                thousandSeparator
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell className="cellSeveralCenterMix">
                                            <strong>Total costos</strong>
                                        </StyledTableCell>
                                        <StyledTableCell className="toRight cellSeveralMixRight">
                                            <CurrencyFormat
                                                renderText={(value) => <b>{value}</b>}
                                                displayType="text"
                                                value={totalPrice}
                                                thousandSeparator
                                                prefix="$ "
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </>
                            )}
                            */}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}