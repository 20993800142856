import React, { useMemo, useEffect, useState } from "react";
import { Grid, Paper, Box } from "@mui/material";
import ApprovalRequestList from "./ApprovalRequestList";
import { useMainContext } from "../../../hooks/useMainContext";
import { roles } from "../../../utils/constants";
import { getData } from "../../../apiCore/entity";
import { useSnackbar } from "notistack";
import CurrencyFormat from "react-currency-format";

export default function ApprovalRequest({ quoteId, onClose, onReloadDatatable }) {

    const { enqueueSnackbar } = useSnackbar();
    const [ mixes, setMixes ] = useState([]);
    const { user } = useMainContext() || {};
    const isRTCJunior = useMemo(() => user?.roleId === roles.RTCJunior, [user]);

    useEffect(() => {
        (async () => {
            try {

                const response = await getData({ model:"mixDetails", idSearch:quoteId, column:"quoteId" });
                const { error, data } = response?.data || {};
                if (error) throw { error: response };

                if (data){
                    console.log(data);
                    setMixes(data);
                }
            } catch (err) {
                const { error } = err?.response?.data || {};
                if (error) {
                    enqueueSnackbar(error, { variant: "error" });
                } else {
                    enqueueSnackbar("Error al cargar los datos de la cotización", { variant: "error" });
                }
            }

        })();
    }, []);

    return (
        <Grid
            className="panel approvalRequest"
            container
            component={Paper}
            elevation={0}>
            <Grid xs={12}>
                <b>
                    Detalles de las mezclas
                </b>
            </Grid>            
            <Grid container item xs={12} sx={{ paddingY: 1, fontWeight: 500 }} style={{fontSize:"12px"}}>
                <Grid item xs={2}>
                    Mezcla
                </Grid>
                <Grid item xs={2}>
                    Rentabilidad
                </Grid>
                <Grid item xs={2}>
                    Toneladas
                </Grid>
                <Grid item xs={2}>
                    Precio x<br/>tonelada
                </Grid>
                <Grid item xs={2}>
                    Flete x<br/>tonelada
                </Grid>
                <Grid item xs={2}>
                    Precio<br/>total
                </Grid>
                <Grid container item xs={12} sx={{ paddingY: 1, borderBottom: "1px solid #bababa", fontWeight: 500 }}></Grid>
                {mixes.map((mix, index)=>{

                    let name = mix.name;
                    let profitability = parseFloat(mix.profitability);
                    let quantityTons = parseFloat(mix.quantityTons);
                    let price = parseFloat(mix.price);
                    let flete = parseFloat(mix.flete);
                    let maquila = parseFloat(mix.maquilaCost);
                    let packageCapacity = parseFloat(mix.value);
                    let pricePerPackage = parseFloat(mix.packagingUnitPrice);
                    let priceByTon = (((price + maquila + ((1000)/packageCapacity)*pricePerPackage)) / (1-(profitability/100)));
                    let totalPricePerMix = (priceByTon + flete) * quantityTons;

                    return <Grid 
                                key={index}
                                container
                                item
                                xs={12}>
                            <Grid item xs={2}>
                                {mix.name}
                            </Grid>
                            <Grid item xs={2}>
                                {profitability.toFixed(3)}%
                            </Grid>
                            <Grid item xs={2}>
                                {quantityTons}
                            </Grid>
                            <Grid item xs={2}>
                                <CurrencyFormat
                                    value={priceByTon.toFixed(0)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <CurrencyFormat
                                    value={flete}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <CurrencyFormat
                                    value={totalPricePerMix.toFixed(0)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                />
                            </Grid>
                    </Grid>
                })}
            </Grid>
            <Grid xs={12}><Box p={2}></Box></Grid>
            <Grid xs={12}>
                <b>
                    Historial aprobaciones
                </b>
            </Grid>            
            <Grid container item xs={12} sx={{ paddingY: 1, borderBottom: "1px solid #bababa", fontWeight: 500 }}  style={{fontSize:"12px"}}>
                <Grid item xs={1}>
                    Id
                </Grid>
                <Grid item xs={2}>
                    Estado
                </Grid>
                <Grid item xs={isRTCJunior ? 3 : 2}>
                    Aprovador
                </Grid>
                <Grid item xs={2}>
                    Role
                </Grid>
                <Grid item xs={isRTCJunior ? 4 : 3}>
                    Comentario
                </Grid>
                {(!isRTCJunior) && (
                    <Grid item xs={2}>
                        Acciones
                    </Grid>
                )}
            </Grid>
            <ApprovalRequestList
                quoteId={quoteId}
                isRTCJunior={isRTCJunior}
                onClose={onClose}
                onReloadDatatable={onReloadDatatable}
            />
        </Grid>
    )
}
