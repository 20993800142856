import React, { useState } from "react";
import { ButtonGroup, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useSnackbar } from "notistack";

import GroupsForm from "./GroupsForm";
import ConfirmModal from "../../Controls/ConfirmModal";
import BasicModal from "../../common/BasicModal/BasicModal";
import { ENV } from "../../../utils/constants";
import { hideLoader, showLoader } from "../../Controls/Loader";
import { deleteRegister } from "../../../apiCore/dataTable";

export default function GroupActionsButtons({ id, onReload, row, columns }) {
    return (
        <ButtonGroup>
            <GroupsDeleteButton id={id} onReload={onReload} />
            <GroupsEditButton row={row} columns={columns} onReload={onReload} />
        </ButtonGroup>
    );
}

function GroupsDeleteButton({ id, onReload }) {
    const [open, setOpen] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const onOpenClose = () => setOpen(prevState => !prevState);

    const onDelete = async () => {
        try {
            showLoader();
            const response = await deleteRegister(ENV.CLIENT_ROUTES.GROUPS.MODEL, id);
            const { error, message } = response?.data || {};

            if (error) throw { response };

            if (message) enqueueSnackbar(message, { variant: "success" });

            // Cerra modal.
            onOpenClose();

            // Recargar datos de tabla.
            if (onReload instanceof Function) onReload();
        } catch (err) {
            const { error } = err?.response?.data || {};
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
            } else {
                enqueueSnackbar(`Error al interntar eliminar el registro con ID: ${id}`, { variant: "error" });
            }
        } finally {
            hideLoader();
        }
    }

    return (
        <React.Fragment>
            <IconButton
                aria-label="delete"
                color="inherit"
                size="small"
                onClick={onOpenClose}
            >
                <Delete fontSize="inherit" />
            </IconButton>

            <ConfirmModal
                open={open}
                setOpen={setOpen}
                confirmButtonText="Confirmar Eliminación"
                titleMessage="Borrar Registro"
                confirmMessage={"¿Confirma que desea eliminar el registro con ID: " + id + "?"}
                confirmHandler={onDelete}
            />
        </React.Fragment>
    );
}

function GroupsEditButton({ row, columns, onReload, }) {
    const [open, setOpen] = useState(false);
    const onOpenCloseModal = () => setOpen(preState => !preState);

    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    return (
        <React.Fragment>
            <IconButton
                aria-label="edit"
                color="inherit"
                size="small"
                onClick={onOpenCloseModal}
            >
                <Edit fontSize="inherit" />
            </IconButton>

            <BasicModal
                open={open}
                onClose={onOpenCloseModal}
                title="Edición de datos"
                fullScreen={fullScreen}
                sxDialogContent={{ padding: 0 }}>
                <GroupsForm columns={columns} onReload={onReload} onClose={onOpenCloseModal} row={row} />
            </BasicModal>
        </React.Fragment>
    );
}
