import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, } from "@mui/material";
import { Close } from "@mui/icons-material";

import { Transition } from "../Transition/Transition";

export default function BasicModal({ open, onClose, title, children, content, actions, sxDialogContent, sxDialogActions, showIconClose = true, ...restProps }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    overflow: "inherit",
                }
            }}
            {...restProps}>
            <DialogTitle
                className="dialogTitle whiteLetter"
                sx={{
                    fontSize: {
                        xs: "1.1rem",
                        sm: "1.25rem",
                    },
                }}>
                {onClose && showIconClose ? (
                    <IconButton
                        className="whiteLetter"
                        edge="start"
                        aria-label="close"
                        onClick={onClose}>
                        <Close />
                    </IconButton>
                ) : null}
                {title}
            </DialogTitle>
            {children && (
                <DialogContent
                    sx={{
                        paddingX: 1,
                        ...sxDialogContent
                    }}>
                    {children}
                </DialogContent>
            )}
            {actions && (
                <DialogActions
                    sx={{
                        ...sxDialogActions,
                    }}>
                    {actions}
                </DialogActions>
            )}
        </Dialog>
    )
}
