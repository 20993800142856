import React, { useEffect, useMemo, useState } from "react";
import { Box, TextField, Typography, Grid } from "@mui/material";
import SelectAutocomplete from "../../Controls/SelectAutocomplete";
import { CustomStack } from "../QuoteForm/QuoterForm";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./QuoteFormCost.form";
import CurrencyFormat from "react-currency-format";
import api from "../../../axios/axios";
import { hideLoader, showLoader } from "../../Controls/Loader";
import { useSnackbar } from "notistack";
import { updateRegisterMix } from "../../../apiCore/entity";
import { ENV } from "../../../utils/constants";
import md5 from "md5";

// Componente para evitar advertencias de propTypes de CurrencyFormat cuando se usa la propiedad customInput
const CustomTextField = inputProps => <TextField {...inputProps} />

export default function QuoteFormCost({ mix, onReload, closeModal, availableEdit, profit, setNegativeProfitability }) {
    const { enqueueSnackbar } = useSnackbar();
    const [isError, setIsError] = useState(false);

    const [profitabilityPerTon, setProfitabilityPerTon] = useState("");
    const [valueProfitability, setValueProfitability] = useState(mix?.profitability ? mix?.profitability:"");    

    //La rentabilidad es definida a nivel general
    //Solo se asigna si la mezcla no tiene rentabilidad inicial, en los casos de edición, no se asigna una rentabilidad a partir de la general, para
    //Evitar modificar las condiciones del negocio.
    //if(!mix.profitability){
        //console.log("Mezcla con rentabilidad indefinida");
        //mix.profitability = profit.value;
    //}

    const formik = useFormik({
        initialValues: initialValues({ mix }),
        validationSchema: validationSchema(),
        validateOnChange: true,
        onSubmit: async (values) => {
            if (!availableEdit) {
                enqueueSnackbar("La cotización no se puede editar", { variant: "error" });
                return;
            }

            let dataMix = { ...values };
            let packaging = dataMix?.packaging;
            delete dataMix?.packaging;
            let idQuery = mix?.id ? mix?.id:mix?.mixId

            try {
                showLoader();
                // Registrar costos a la cotización
                const response = await updateRegisterMix({model: ENV.CLIENT_ROUTES.MIXES.MODEL, id: idQuery, data: dataMix})

                const { error, message } = response?.data;

                //Puede existir un error de comunicacion pero se controla debido a la operacion offline
                if (error) {
                    //enqueueSnackbar(error, { variant: "error" });
                    hideLoader();
                    return;
                }

                if (message) {
                    enqueueSnackbar(message, { variant: "success" });
                    if (closeModal instanceof Function) closeModal();
                }

                if (onReload instanceof Function) {
                    onReload({...response?.data?.data[0], quoteId: mix?.quoteId, mixId:mix?.mixId, packaging:packaging, });
                }

                hideLoader();

            } catch (err) {
                hideLoader();                
                const { error } = err?.response?.data || {};
                if (error) {
                    enqueueSnackbar(error, { variant: "error" });
                } else {
                    enqueueSnackbar("Error al registrar los datos de la mezcla", { variant: "error" });
                }
            }

        }
    });

    // Calcular el costo de las materias primas
    // Formula: quantityTons * precio por tonelada.
    const rawMaterialCost = useMemo(() => {
        const quantityTons = Number(formik.values.quantityTons) || 0;
        const price = mix?.price || 0;
        const totalTons = mix?.totalTons || 0;
        const pricePerTon = (Number(price / totalTons) || 0) * 1000;

        // result = Number(pricePerTon * quantityTons);
        return Number(pricePerTon * quantityTons);

        // return parseFloat(result).toFixed(2);
    }, [mix, formik.values.quantityTons]);

    const maquilaCost = useMemo(() => {
        return (((Number(formik.values.maquilaCost) * Number(formik.values.quantityTons)) || 0) + Number(rawMaterialCost)) || 0;
    }, [formik.values]);


    const maquilaCostPerTon = useMemo(() => {
        let value = Number(formik.values.maquilaCost);

        return (value + mix?.price) || mix?.price;
    }, [formik.values]);


    // Calcular el número de empaques
    const calculateQuantityPackaging = useMemo(() => {
        let result = 0;
        const totalTons = Number(mix?.totalTons) || 0;
        const packagingCapacity = Number(formik.values?.packaging?.value) || 0;
        const quantityTons = Number(formik.values.quantityTons) || 0;

        // result = Number(totalTons / packagingCapacity) || 0;
        result = (Number(1000 / packagingCapacity) * quantityTons) || 0;
        if (!isFinite(result)) {
            result = 0;
        }
        return result;
    }, [mix, formik.values]);

    // Calcular el número de empaques
    const calculateQuantityPackagingPerTon = useMemo(() => {
        let result = 0;
        const totalTons = Number(mix?.totalTons) || 0;
        const packagingCapacity = Number(formik.values?.packaging?.value) || 0;
        const quantityTons = Number(formik.values.quantityTons) || 0;

        // result = Number(totalTons / packagingCapacity) || 0;
        result = (Number(1000 / packagingCapacity)) || 0;
        if (!isFinite(result)) {
            result = 0;
        }
        return result;
    }, [mix, formik.values]);    

    // Calcular el subtotal del costo de la cotización.
    // Precio + costo maquila + (número empaques * valor unitario)
    const subtotal = useMemo(() => {
        const quantityPackaging = Number(calculateQuantityPackaging) || 0;
        const packaginUnitPrice = Number(formik.values.packagingUnitPrice) || 0;
        const vallueReturn = Number(Number(maquilaCost) + Number((quantityPackaging * packaginUnitPrice))) || 0;
        return vallueReturn;
    }, [formik.values, maquilaCost]);

    const subtotalPerTon = useMemo(() => {
        const quantityPackaging = Number(calculateQuantityPackagingPerTon) || 0;
        const packaginUnitPrice = Number(formik.values.packagingUnitPrice) || 0;
        let value = Number(Number(maquilaCostPerTon) + Number((quantityPackaging * packaginUnitPrice))) || 0;
        console.log(value)
        return value;
    }, [formik.values, maquilaCostPerTon]);    

    const profitability = useMemo(() => {        
        const profitabilityPercentage = Number(formik.values.profitability / 100) || 0;

        let value = Number(subtotal / (1 - profitabilityPercentage)) || 0;
        value = value.toFixed(0);
        value = Math.ceil(value/100)*100
        return value;
    }, [subtotal, formik.values.profitability]);

    // Calcula el valor con el descuento.
    const discount = useMemo(() => {
        const discountPercentage = Number(formik.values.discount / 100) || 0;
        return Number(profitability * (1 - discountPercentage)) || 0;
    }, [profitability, formik.values.discount]);

    // Calcula el valor del flete por el número de toneladas.
    const totalFlete = useMemo(() => {
        return (Number(formik.values.flete) * Number(formik.values.quantityTons)) || 0
    }, [formik.values.flete])

    // Calcula el total del costo de la cotización.
    const total = useMemo(() => {
        return (Number(discount) + Number(totalFlete)) || 0;
    }, [discount, formik.values]);

    useEffect(() => {
        function init(){
            if(mix?.profitability){
                console.log(subtotalPerTon);
                console.log(valueProfitability);
                let valueTotalWithProfitability = subtotalPerTon / (1 - (valueProfitability/100));
                setProfitabilityPerTon(valueTotalWithProfitability.toFixed(0));
            }
        };

        init();
    }, [subtotalPerTon]);

    if (isError) return <Box>Error al cargar la información, por favor intente más tarde.</Box>

    return (
        <Box
            className="displayFlex flexDirectionColumn height100 noMargin">
            <Box>
                <Typography
                    variant="h6"
                    fontSize={{
                        xs: "1.1rem",
                        sm: "1.25rem",
                    }}>
                    {mix?.name}
                </Typography>
                <Typography variant="subtitle1">
                    <b>{mix?.type?.name}</b>
                </Typography>
            </Box>
            <br/>
            <Box
                component={"form"}
                onSubmit={formik.handleSubmit}
                id="quote-cost-form">
                <CustomStack>
                    <TextField
                        InputProps={{inputProps: { min: 0 }}}
                        fullWidth
                        label="Cantidad de toneladas"
                        name="quantityTons"
                        disabled={!availableEdit}
                        value={formik.values.quantityTons}
                        onChange={formik.handleChange}
                        onFocus = {() => {
                            formik.setFieldValue("quantityTons", "");
                        }}
                        error={formik.touched.quantityTons && Boolean(formik.errors.quantityTons)}
                        helperText={formik.touched.quantityTons && formik.errors.quantityTons}/>

                    <CurrencyFormat
                        disabled
                        fullWidth
                        customInput={CustomTextField}
                        label="Costo materia prima por tonelada"
                        value={parseFloat(mix?.price).toFixed(0)}
                        thousandSeparator
                        prefix="$"/>

                    <CurrencyFormat
                        disabled
                        fullWidth
                        customInput={CustomTextField}
                        label="Costo materia prima"
                        value={parseFloat(rawMaterialCost).toFixed(0)}
                        thousandSeparator
                        prefix="$"/>
                </CustomStack>
                <CustomStack>
                    <SelectAutocomplete
                        model="packagingType"
                        label="Tipo de empaque"
                        column="name"
                        disabled={!availableEdit}
                        value={mix?.packagingTypeId}
                        sx={{
                            width: "100%"
                        }}
                        onChange={async (id, _, __, value) => {
                            if(value){
                                await formik.setFieldValue("packagingTypeId", id ?? "");
                                formik.setFieldValue("packagingUnitPrice", value?.unitPrice ?? "");
                                formik.setFieldValue("packaging", value);
                                await formik.setFieldValue("maquilaCost", value.maquilaPrice);
                            }else{
                                await formik.setFieldValue("packagingTypeId", "");
                                formik.setFieldValue("packagingUnitPrice", "");
                                formik.setFieldValue("packaging", "");
                                await formik.setFieldValue("maquilaCost", "");
                            }

                            //Si se cambia el tipo de empaque, como cambia el valor por tonelada, la rentabilidad y su valor se deben redefinir
                            setProfitabilityPerTon("");
                            setValueProfitability("")
                        }}
                        textfieldError={formik?.touched?.packagingTypeId && Boolean(formik?.errors?.packagingTypeId)}
                        textfieldHelpertext={formik?.touched?.packagingTypeId && formik?.errors?.packagingTypeId}
                        getDefaultValue={(Option) => {
                            if (Option) {
                                formik.setFieldValue("packagingTypeId", Option?.id);
                                formik.setFieldValue("packagingUnitPrice", Option?.unitPrice);
                                formik.setFieldValue("packaging", Option);
                            }
                        }}/>
                    <CurrencyFormat
                        fullWidth
                        customInput={CustomTextField}
                        thousandSeparator
                        disabled
                        value={parseFloat(calculateQuantityPackagingPerTon).toFixed(1)}
                        label="Cantidad de empaques x tonelada"
                        name="quantityPackagingPerTon"/>                    
                    <CurrencyFormat
                        fullWidth
                        customInput={CustomTextField}
                        thousandSeparator
                        disabled
                        value={parseFloat(calculateQuantityPackaging).toFixed(1)}
                        label="Cantidad de empaques"
                        name="quantityPackaging"/>
                </CustomStack>  
                <CustomStack>
                    <CurrencyFormat
                        fullWidth
                        customInput={CustomTextField}
                        disabled
                        label="Maquila"
                        name="maquilaCost"
                        thousandSeparator
                        prefix=" $"
                        value={formik.values.maquilaCost}
                        onChange={formik.handleChange}
                        error={formik.touched.maquilaCost && Boolean(formik.errors.maquilaCost)}
                        helperText={formik.touched.maquilaCost && formik.errors.maquilaCost}/>

                    <CurrencyFormat
                        customInput={CustomTextField}
                        disabled
                        fullWidth
                        label="Costo + (maquila x tonelada)"
                        value={parseFloat(maquilaCostPerTon).toFixed(0)}
                        thousandSeparator
                        prefix="$"/>

                    <CurrencyFormat
                        customInput={CustomTextField}
                        disabled
                        fullWidth
                        label="Costo + (maquila x toneladas)"
                        value={parseFloat(maquilaCost).toFixed(0)}
                        thousandSeparator
                        prefix="$"/>
                </CustomStack>
                <CustomStack>    
                    <Grid item xs={12}></Grid>                  
                    <CurrencyFormat
                        customInput={CustomTextField}
                        thousandSeparator
                        fullWidth
                        prefix="$"
                        disabled
                        value={parseFloat(subtotalPerTon).toFixed(0)}
                        label={"Costo + maquila + empaques (" + calculateQuantityPackagingPerTon + ") x tonelada"}
                    />
                    <CurrencyFormat
                        customInput={CustomTextField}
                        thousandSeparator
                        fullWidth
                        prefix="$"
                        disabled
                        value={parseFloat(subtotal).toFixed(0)}
                        label={"Costo + maquila + empaques (" + calculateQuantityPackaging + ")"}
                    />
                </CustomStack>
                <CustomStack>
                    <CurrencyFormat
                        customInput={CustomTextField}
                        thousandSeparator
                        fullWidth
                        decimalScale={3}
                        prefix=""
                        label="% Rentabilidad"
                        name="valueProfitability"
                        value={valueProfitability}
                        onFocus = {() => {
                            setValueProfitability("");
                            setProfitabilityPerTon("");
                            formik.setFieldValue("profitability", "");
                        }}
                        onValueChange={(values)=>{
                            const {formattedValue, value} = values;
                            setValueProfitability(value);

                            if(value <= 0){
                                setNegativeProfitability(true);
                            }else{
                                setNegativeProfitability(false);
                            }

                            //Calcuo del valor con base en la rentabilidad
                            let valueTotalWithProfitability =  subtotalPerTon / (1 - (value/100));
                            setProfitabilityPerTon(valueTotalWithProfitability.toFixed(0));
                            //Actualización de rentabilidad formulario
                            formik.setFieldValue("profitability", value);                           
                        }}
                        error={formik.touched.profitability && Boolean(formik.errors.profitability)}
                        helperText={formik.touched.profitability && formik.errors.profitability}/>
                    <CurrencyFormat
                        customInput={CustomTextField}
                        thousandSeparator
                        fullWidth
                        prefix="$"
                        label="Precio + rentabilidad x tonelada"
                        name="rentabilidadPricePerTon"
                        value={profitabilityPerTon}
                        onFocus = {() => {
                            setProfitabilityPerTon("");
                            formik.setFieldValue("profitability", 0);
                        }}
                        onValueChange={(values)=>{
                            const {formattedValue, value} = values;

                            let valueWithProfitabilityInput = value;
                            let valuePercentualProfitability = 1 - (subtotalPerTon/valueWithProfitabilityInput);
                            valuePercentualProfitability = valuePercentualProfitability * 100;

                            if(valuePercentualProfitability != 0){
                                setValueProfitability(valuePercentualProfitability.toFixed(10));

                                if(valuePercentualProfitability <= 0){
                                    setNegativeProfitability(true);
                                }else{
                                    setNegativeProfitability(false);
                                }

                                formik.setFieldValue("profitability", valuePercentualProfitability.toFixed(10));
                            }else{
                                setValueProfitability(0);
                                formik.setFieldValue("profitability", 0);
                            }

                            setProfitabilityPerTon(valueWithProfitabilityInput);

                        }}
                        error={formik.touched.profitability && Boolean(formik.errors.profitability)}
                        helperText={formik.touched.profitability && formik.errors.profitability}/>

                    <Grid item xs={12}>
                        <CurrencyFormat
                            customInput={CustomTextField}
                            decimalScale={0}
                            thousandSeparator
                            fullWidth
                            prefix="$"
                            disabled
                            label="Precio"
                            name="rentabilidadPrice"
                            value={profitability}
                        />
                        <p className="littleInfoText">Campo redondeado al 100 mas cercano por encima.</p>
                    </Grid>
                </CustomStack>
                {/*
                <CustomStack>
                    <TextField
                        hidden
                        fullWidth
                        name="discount"
                        value={formik.values.discount}
                        onChange={formik.handleChange}
                        label="% Descuento"
                        disabled={!availableEdit}
                        error={formik.touched.discount && Boolean(formik.errors.discount)}
                        helperText={formik.touched.discount && formik.errors.discount}/>
                    <CurrencyFormat
                        hidden                    
                        customInput={CustomTextField}
                        thousandSeparator
                        fullWidth
                        prefix="$"
                        disabled
                        value={parseFloat(discount).toFixed(0)}
                        label="Precio + descuento"/>
                </CustomStack>
                */}
                <CustomStack>
                    <Grid item xs={12}></Grid>                    
                    <CurrencyFormat
                        customInput={CustomTextField}
                        fullWidth
                        thousandSeparator
                        prefix="$"                        
                        value={formik.values.flete}
                        disabled={!availableEdit}
                        name="flete"
                        label="Flete por tonelada"
                        onFocus = {() => {
                            formik.setFieldValue("flete", "");
                        }}
                        onValueChange={(values)=>{
                            const {formattedValue, value} = values;
                            formik.setFieldValue("flete",value);
                        }}
                        error={formik.touched.flete && Boolean(formik.errors.flete)}
                        helperText={formik.touched.flete && formik.errors.flete}/>

                    <CurrencyFormat
                        customInput={CustomTextField}
                        fullWidth
                        thousandSeparator
                        prefix="$"
                        disabled
                        value={parseFloat(totalFlete).toFixed(0)}
                        name="fletePerTnPrice"
                        label="Flete total"/>
                </CustomStack>

                <CustomStack>
                    <div style={{ width: "100%", display: "inline" }} />
                    <Grid item xs={12}></Grid>
                    <CurrencyFormat
                        customInput={CustomTextField}
                        disabled
                        fullWidth
                        label="Precio + flete"
                        name="total"
                        value={parseFloat(total).toFixed(0)}
                        thousandSeparator
                        prefix="$"/>
                </CustomStack>
            </Box>
        </Box >
    )
}
